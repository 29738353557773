import React, { useState, useEffect  } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Jumbotron, Container, Form, Button, Modal, Image, Toast, Spinner, Nav, Card } from 'react-bootstrap';
import axios from 'axios';
import CareerBanner from '../images/banners/CurrentOpenings1-banner.jpg';
import { Link } from 'react-router-dom';
// import openingData from './Includes/CurrentOpenings.json';

const TITLE = "Job Application - XL Dynamics India Pvt. Ltd"
//  Custom CSS
const treasury = {
    opacity: 0,
    position: 'absolute'
}
// End of Custom CSS
function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for(let i=0; i < inputs.length; i++) {
        testArray[(inputs[i].id)] = inputs[i].value;
    }
    return (testArray);
}

function ApplyNow({ match, location, employeeID}) {
    // console.log(this.props)
    const [getData, setgetData] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then(response => response.json())
        .then(
            // handle the result
            (result) => {
                setgetData(result)
            }
        )
    },[]);
    const {
        params: {OpeningName}
    } = match ? match : {params:{OpeningName: null}}
    var selectOptionNew = ""
    var selectedOpeningCode;
    const selectedOption = getData.map(oData => {
        let title = oData.openingTitle;
        if(OpeningName === oData.openingCode && oData.visible){
            selectOptionNew = title
            selectedOpeningCode = true
            return <option key={oData.openingId} value={title}>{title}</option>;
        } else{ return null;}
    });

    const openingsList = getData.map(oData => {
        let title = oData.openingTitle;
        if(OpeningName !== oData.openingCode && oData.visible){
            return <option key={oData.openingId} value={title}>{title}</option>;
        }
    });


    const [validated, setValidated] = useState(false);
    
    const [showTY, setTYModal] = useState(false)
    const [showFail, setFailModal] = useState(false)

    const handleTYClose = () => setTYModal(false)
    const handleFailClose = () => setFailModal(false)

    const [selectedFile, setFileSelection] = useState(null);
    const [loading, setLoading] = useState(false);

    const [successToast, setSuccessToast] = useState(false);
    const [warningToast, setWarningToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const [url, setUrl] = useState('');

    function refreshPage() {
        window.location.reload(false);
        setTYModal(false)        
        setFailModal(false)
    }

    
    const checkMimeType = (event)=>{
        //getting file object
        let files = event.target.files 
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        
        // loop access array
        for(var x = 0; x<files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = 'File format is not supported for upload.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
        }
        return true;
    }
    
    const maxSelectFile = (event)=>{
        let files = event.target.files
        if (files.length > 1) { 
            setToastMsg('Only 1 file can be uploaded at a time');
            event.target.value = null;
            setWarningToast(true);
            return false;
        }
        return true;
    }

    const checkFileSize = (event)=>{
        let files = event.target.files
        let size = 2000000 
        let err = []; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+' is too large, please pick a smaller file.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
        }
        return true;
    }

    const onChangeHandler = event => {
        var files = event.target.files;
        console.log(files)
        if(maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
            setFileSelection(files);
        }
        if(files.length > 0){
            setWarningToast(false);
        }
        //console.log('selectedFileselectedFile', selectedFile);
    }

    const sendMail = (error) => {
        const config = {
            headers: {
                // "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
            },
        }

        var errorStack = {
            "user_id": "user_55de4Ba680qIvebceTFXm",
            "service_id": "service_heo5o8k",
            "template_id": "template_77jm83o",
            "template_params": {
                    "from_name": "API Error",
                    "to_name": "xldynamics.com",
                    "message": "API Error status " +  JSON.stringify(error)
                }
            }
        axios.post("https://api.emailjs.com/api/v1.0/email/send", errorStack, config)
        .then(res =>{
            console.log(res);
        })
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    const handleSubmit = async event => {    
        event.preventDefault();
        const form = event.currentTarget;
        // const data = document.querySelector('#applyNow');
        // const getData = serialize(data);
        // console.log(validated);
        // console.log(getData); 
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        myHeaders.append('Content-Type', 'application/json',)
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Headers", "*");
        myHeaders.append("Access-Control-Allow-Methods", "*");

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            //upload file to aws 
            // let file = {}
            if(selectedFile && selectedFile.length > 0){
                var fileParts = selectedFile[0].name.split('.')
                var file = fileParts[0].replace(/\s/g, '')+Date.now();
                var fileName = file+"."+fileParts[1]
                const baseFile = await toBase64(selectedFile[0]);
                const body = {
                    file: baseFile,
                    name: fileName
                }
                axios.post("https://l8fd98lyc2.execute-api.us-east-1.amazonaws.com/prod/python-s3-upload-new", body)                    
                        .then(response => {
                            const data = document.querySelector('#applyNow');
                            const getData = serialize(data);
                            getData.jobFile = response.data.URL
                            if (employeeID && employeeID.emp && employeeID.emp != ""){
                                getData.emp = atob(employeeID.emp)
                            } else if (employeeID == "Dolphins") {
                                getData.emp = employeeID
                            } else {
                                getData.emp = "Main-Website"
                            }
                            let applyNow = document.getElementById("applyNow");

                            // if ((employeeID && employeeID.emp && employeeID.emp != "") || employeeID == "Dolphins"){
                                const today = new Date()
                                let date = parseInt(today.getMonth()+1) + "/"+ today.getDate() +"/"+today.getFullYear();
                                
                                const referEmp = {
                                    "method": "post",
                                    headers: myHeaders,
                                    "emp": getData.emp,
                                    "firstName": getData.jobFName,                                
                                    "lastName": getData.jobLName,
                                    "email": getData.jobEmail,
                                    "contactNumber": getData.jobCellNumber,
                                    "address": getData.jobState,
                                    "city": getData.jobCity,
                                    "Total Experience": getData.jobExperience,
                                    "Total CTC": getData.jobCTC,
                                    "jobSelect": getData.jobSelect,
                                    "resume": getData.jobFile,
                                    "date": date
                                }
                                //https://12cjv4aldg.execute-api.us-west-1.amazonaws.com/prod/referral-Data
                                axios.post(process.env.REACT_APP_REFERRAL_DATA_API, referEmp)
                                    .then(res => {
                                        console.log(res)
                                        // sendMail(res)
                                    })
                                    .catch(function(error) {
                                        var emailError = {
                                            "messageFrom": "referral-data API",
                                            "error": error
                                        }
                                        // sendMail(emailError);
                                        console.log("referral-Data error status " +  JSON.stringify(error));
                                    })
                            // }
                            if(getData.emp == "Main-Website" || getData.emp == "Dolphins") {
                                axios.post(`https://nqdk4jkj2c.execute-api.us-east-1.amazonaws.com/development/`, getData)
                                .then(res =>  {
                                    console.log(res);
                                    console.log(res.data);
                                    setLoading(false);
                                    setTYModal(true);
                                    setFailModal(false);
                                    applyNow.classList.remove("was-validated");
                                    applyNow.reset();
                                })
                                .catch(function (error) {
                                    setFailModal(true);
                                    var emailError = {
                                        "messageFrom": "development Email API",
                                        "error": error
                                    } 
                                    // sendMail(emailError);

                                    applyNow.classList.remove("was-validated");
                                    applyNow.reset();
                                    console.log("development error status " +  JSON.stringify(error));
                                })
                            } else {
                                setLoading(false);
                                setTYModal(true);
                                setFailModal(false);
                                applyNow.classList.remove("was-validated");
                                applyNow.reset();
                            }
                        })
                        .catch(error => { 
                            var emailError = {
                                "messageFrom": "python-s3-upload-new API",
                                "error": error
                            }     
                            // sendMail(emailError);
                            setFailModal(true);
                            // alert("ERROR " + JSON.stringify(error));
                            console.log("Put error status " +  JSON.stringify(error));
                        })
                
            } else {
                setToastMsg('Please select the resume file ');
                setWarningToast(true)
            }
        }
        // setLoading(false);
        setValidated(true);
    };

    return(    
        <React.Fragment>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            {selectedOpeningCode ? 
                <Row className="removeMarginLR">
                    {/* <Image className={"w-100 "+(location.search.length > 0 || location.pathname == "/dolphins" ? ' d-none' : ' d-block')} style={{padding:"10px"}} src={CareerBanner} fluid /> */}
                    <Col xs={1}></Col>
                    <Col xs={10} className="small-screen">
                        <h1 className={"x-head "+(location.search.length > 0 || location.pathname == "/dolphins" ? ' d-none' : ' d-block')}  style={{height: "50px"}}>Job Application</h1><br/>
                        <h5 className={"themeTextRed " +(location.search.length > 0 || location.pathname == "/dolphins" ? ' d-none' : ' d-block')} >Personal Information:</h5>
                        <Jumbotron style={{backgroundColor: 'white'}} fluid>
                            <Container fluid>
                                <Form id="applyNow" noValidate validated={validated} encType="multipart/form-data" onSubmit={handleSubmit} method="POST" autoComplete="off">
                                    <Form.Row style={treasury}>
                                        <Form.Group as={Col} sm={5} controlId="xld_name">
                                            <strong><Form.Label>* XLDynamics Name: </Form.Label></strong>
                                            <Form.Control type="text" minLength="2" maxLength="12" data-error="Please fill out this field correctly." />
                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={5} controlId="xld_code">
                                            <strong><Form.Label>* XLDynamics Code: </Form.Label></strong>
                                            <Form.Control type="text" pattern="^[0-9a-zA-Z]{4}$" maxLength="4" data-error="Please fill out this field correctly." />
                                            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobFName">
                                            <strong><Form.Label>* First Name:</Form.Label></strong>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Enter First Name" 
                                                pattern="[A-Za-z]+" 
                                                title="Only letters are allowed, no spaces or special characters" 
                                                required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobLName">
                                            <strong><Form.Label>* Last Name:</Form.Label></strong>
                                            <Form.Control type="text" 
                                                placeholder="Enter Last Name" 
                                                pattern="[A-Za-z]+" 
                                                required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    {/* <Form.Row className="refer-hide">
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} controlId="jobCellNumber">
                                            <strong><Form.Label>* Contact Number:</Form.Label></strong>
                                            <Form.Control type="tel" maxLength="10" data-minlength="10" autoComplete="off" pattern="^[0-9]{10}$" required />
                                            <Form.Control.Feedback type="invalid">Please enter a valid Contact Number</Form.Control.Feedback>
                                        </Form.Group>
                                        
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} controlId="jobEmail">
                                            <strong><Form.Label>* E-Mail Address:</Form.Label></strong>
                                            <Form.Control type="text" placeholder="Please enter Your E-Mail Address" pattern="^([A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-])+.\.([A-Za-z]{2,4})$" autoComplete="off" required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row> */}

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobGender" >
                                            <strong><Form.Label>* Gender:</Form.Label></strong>
                                            <Form.Control as="select" placeholder="Select Gender" required>
                                                <option></option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Others">Others</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">Please select an option.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobSelect">
                                            <strong><Form.Label>* Desired Position:</Form.Label></strong>
                                            <Form.Control as="select" required>
                                                {selectedOption}
                                                {selectedOption?'':<option></option>}
                                                {openingsList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>                                                                

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobEmail">
                                            <strong><Form.Label>* E-Mail Address:</Form.Label></strong>
                                            <Form.Control type="email" placeholder="Enter E-Mail Address" autoComplete="off" required />
                                            {/* <input type="email" className="form-control" name="email" pattern="[A-Za-z0-9_\-\.])+@([A-Za-z0-9_\-])+.\.([A-Za-z]{2,4})$" required/> */}
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobCellNumber">
                                            <strong><Form.Label>* Telephone:</Form.Label></strong>
                                            <Form.Control type="tel" placeholder="Enter Telephone Number" maxLength="10" data-minlength="10" autoComplete="off" pattern="^[0-9]{10}$" required />
                                            <Form.Control.Feedback type="invalid">Please enter a valid Contact Number</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobStreetAdd">
                                            <strong><Form.Label>* Street Address:</Form.Label></strong>
                                            <Form.Control type="text" placeholder="Enter Street Address" required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobZipCode">
                                            <strong><Form.Label>* Zip Code:</Form.Label></strong>
                                            <Form.Control type="text" placeholder="Enter Zip Code" required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobCity">
                                            <strong><Form.Label>* City:</Form.Label></strong>
                                            <Form.Control type="text" placeholder="Enter City" required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobState">
                                            <strong><Form.Label>* State:</Form.Label></strong>
                                            <Form.Control type="text" placeholder="Enter State" required />
                                            <Form.Control.Feedback type="invalid">Please fill out this field correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobExperience">
                                            <strong><Form.Label>* Total Experience:</Form.Label></strong>
                                            <Form.Control type="number" pattern="^\d{1,2}$" placeholder="Enter Total Experience" required />
                                            <Form.Control.Feedback type="invalid">Please enter Total Experience in number correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobCTC">
                                            <strong><Form.Label>* Current CTC:</Form.Label></strong>
                                            <Form.Control type="number" pattern="^\d{1,2}$" placeholder="Enter Current CTC" required />
                                            <Form.Control.Feedback type="invalid">Please enter Current CTC in number correctly.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className="refer-hide">
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={11} className="minimum-screen" controlId="jobComments">
                                            <strong><Form.Label>Comments:</Form.Label></strong>
                                            <Form.Control as="textarea" rows="3" minLength={10} />
                                            <Form.Control.Feedback type="invalid">Please enter minimum 10 characters.</Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    
                                    <Form.Row >
                                        <Form.Group as={Col} sm={1}>&nbsp;</Form.Group>
                                        <Form.Group as={Col} sm={5} className="minimum-screen" controlId="jobFile" >
                                            <strong><Form.Label>* Upload Resume:</Form.Label></strong>
                                            <div className="custom-file">
                                            <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatedCustomFile" required />
                                            <Form.Label className="custom-file-label" value="selected" for="validatedCustomFile">
                                                {selectedFile && selectedFile.length > 0 && selectedFile[0].name ?<span className="filename-trim">{selectedFile[0].name} </span> : 'Choose your Resume File...'}</Form.Label>
                                            {/* {selectedFile[0].name ? selectedFile : 'Choose your Resume File...'} */}
                                            <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                            <Toast  show={warningToast} delay={5000} className="error-msg-block" autohide>
                                                <Toast.Header closeButton={false}>
                                                <strong className="mr-auto error-msg">{toastMsg}</strong>
                                                </Toast.Header>
                                            </Toast>
                                        </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            {/* <Toast onClose={() => setWarningToast(false)} show={warningToast} delay={5000} className="error-msg border-danger" autohide>
                                                <Toast.Header>
                                                <strong className="mr-auto">{toastMsg}</strong>
                                                </Toast.Header>
                                            </Toast> */}
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Toast onClose={() => setSuccessToast(false)} show={successToast} delay={5000} className="border-success" autohide>
                                                <Toast.Header>
                                                    <strong className="mr-auto">{toastMsg}</strong>
                                                </Toast.Header>
                                            </Toast>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="text-center apply-button">
                                        <Form.Group as={Col}>
                                            <Button variant="danger" type="btn btn-lg submit" >
                                                {loading?
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />:
                                                "Apply Now"}
                                                {loading?" Please wait...":""}
                                            </Button>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} sm={1}>&nbsp;</Form.Group> */}
                                    </Form.Row>
                                </Form>
                            </Container>
                        </Jumbotron>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
                :
                <h1 className="text-center">WE ARE SORRY.<br/>BUT THE PAGE YOU REQUESTED WAS NOT FOUND</h1>
            }
            <Modal size='md' centered show={showTY} backdrop="static">
                <Modal.Body>Thank you for submitting your request to the XLDynamics Team.</Modal.Body>
                <Modal.Footer>
                    {/* <Button onClick={refreshPage}>Close</Button> */}
                    {/* <Nav.Link as={Link} to={`/ApplyNow/${selectOptionNew}`} className="btn btn-danger btn-sm">Close</Nav.Link> */}
                    <Nav.Link as={Link} to="/current-openings" className="btn btn-danger btn-sm">Close</Nav.Link>
                </Modal.Footer>
            </Modal>
            <Modal size='lg' centered show={showFail} onHide={handleFailClose} backdrop="static">
                <Modal.Body>Due to technical difficulties your request could not be sent. Please check your internet connection and try again or contact <a href="mailto:xldynamics.mbp@gmail.com">xldynamics.mbp@gmail.com.com</a>.</Modal.Body>
                <Modal.Footer>
                    <Button onClick={refreshPage}>Close</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}

export default ApplyNow;